export function convertToSuccess(toastId, message) {
  const { $toast } = useNuxtApp();
  $toast.update(toastId, {
    type: "success",
    render: message,
    isLoading: false,
    closeButton: true,
    closeOnClick: true,
    autoClose: true,
    timeout: 3000,
  });
}

export function convertToError(toastId, message) {
  const { $toast } = useNuxtApp();
  $toast.update(toastId, {
    type: "error",
    render: message,
    isLoading: false,
    closeButton: true,
    closeOnClick: true,
    autoClose: true,
    timeout: 3000,
  });
}

export const errorHandler = (
  err,
  errorRef = null,
  validationErrorsRef = null,
  toastId = null
) => {
  const { $toast } = useNuxtApp();
  if (err.response) {
    if (err.response.status === 422) {
      let message = "Un ou plusieurs champs sont invalides";

      if (err.response.data.message) {
        message = err.response.data.message;
      } else if (err.response.data.error) {
        message = err.response.data.error;
      }

      if (errorRef) {
        errorRef.value = message;
      }

      if (validationErrorsRef) {
        validationErrorsRef.value = err.response.data.errors;
      }

      if (toastId) {
        convertToError(toastId, message);
      } else {
        $toast.error(message);
      }
    } else {
      const message =
        err.response.data.message ||
        err.response.data.error ||
        err ||
        "Une erreur est survenue";

      console.error(err);

      if (errorRef) {
        errorRef.value = message;
      }

      if (toastId) {
        convertToError(toastId, message);
      } else {
        $toast.error(message);
      }
    }
  } else {
    if (toastId) {
      convertToError(toastId, err || "Une erreur est survenue");
    } else {
      $toast.error(err || "Une erreur est survenue");
    }

    if (errorRef) {
      errorRef.value = err || "Une erreur est survenue";
    }

    console.error(err);
  }
};

export const useUtils = () => {
  return {
    convertToSuccess,
    convertToError,
    errorHandler,
  };
};
